import React, { useEffect } from "react"
import { Redirect } from "react-router-dom"
import { useKeycloak } from "@react-keycloak/web"
import { useLocation } from "react-router-dom"

const LoginView = props => {
  const { keycloak, initialized } = useKeycloak()
  const location = useLocation()
  const currentLocationState = location?.state || {
    from: { pathname: "/predictions" },
  }

  useEffect(() => {
    if (keycloak && !keycloak.authenticated) {
      keycloak.login()
    }
  }, [keycloak, initialized])

  if (keycloak?.authenticated) {
    return <Redirect to={currentLocationState.from} />
  }

  return <div></div>
}

export default LoginView
