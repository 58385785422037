import {
  makeStyles,
  Typography,
  Card,
  CardContent,
  CardHeader,
} from "@material-ui/core"

import gql from "graphql-tag"
import { useSubscription } from "@apollo/react-hooks"
import React, { useState } from "react"
import { GradeChangeGraphs } from "./GradeChange"

const GET_LATEST_GRADE_CHANGES = gql`
  subscription Predictions {
    grade_changes(limit: 3, order_by: { change_date: desc }) {
      id
      created_at
      first_heatnum
      second_heatnum
      first_grade_id
      first_grade {
        id
        name
        class
      }
      second_grade_id
      second_grade {
        id
        name
        class
      }
    }
  }
`

const headerStyles = makeStyles({
  root: {
    borderBottom: "1px solid #ddd",
    background: "#f2f2f2",
  },
})

const cardStyles = makeStyles({
  root: {
    marginBottom: "20px",
  },
})

export const PredictionShow = props => {
  const headerClasses = headerStyles()
  const cardClasses = cardStyles()

  const [gradeChanges, setGradeChanges] = useState([])

  useSubscription(GET_LATEST_GRADE_CHANGES, {
    onSubscriptionData: sub => {
      if (!sub.subscriptionData) return
      const { grade_changes } = sub.subscriptionData.data
      setGradeChanges(grade_changes)
    },
  })

  return (
    <>
      {gradeChanges.map(gradeChange => {
        const title = `${gradeChange.first_heatnum} (${gradeChange.first_grade.name}) ->
              ${gradeChange.second_heatnum} (${gradeChange.second_grade.name})`

        return (
          <Card key={gradeChange.id} classes={cardClasses}>
            <CardHeader title={title} classes={headerClasses}></CardHeader>
            <CardContent>
              <Typography variant="h5"></Typography>
              <GradeChangeGraphs gradeChange={gradeChange} />
            </CardContent>
          </Card>
        )
      })}
    </>
  )
}
