import React from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/react-hooks"
import lodash from "lodash"

import {
  Paper,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  withStyles,
  makeStyles,
} from "@material-ui/core"

import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  NumberField,
  Filter,
  TextInput,
} from "react-admin"

// Show tolerances on Grades tab

// Available options: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
const NUMBER_FORMATTING = {
  maximumFractionDigits: 10
}

const GradeFilter = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
  </Filter>
)

export const GradeList = props => (
  <List {...props} filters={<GradeFilter />}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="class" />
    </Datagrid>
  </List>
)

const GRADE_SPEC_QUERY = gql`
  query GetGradeSpec($gradeId: bigint) {
    grade_specs(where: { grade_id: { _eq: $gradeId } }) {
      minimum
      maximum
      grade {
        id
        name
        class
      }
      element {
        name
        symbol
        tolerances {
          id
          class
          overage
          underage
          range_minimum
          range_maximum
        }
      }
    }
  }
`

export const useGradeSpec = gradeId => {
  const { data, loading, error } = useQuery(GRADE_SPEC_QUERY, {
    variables: { gradeId },
  })
  if (loading || error) return {}
  return data.grade_specs.reduce((specs, spec) => {
    const gradeClass = spec.grade.class.toLowerCase()
    const tolerances = lodash.sortBy(
      spec.element.tolerances.filter(t => t.class.toLowerCase() === gradeClass),
      ["range_minimum"]
    )

    let minimumTolerance = spec.minimum
    let maximumTolerance = spec.maximum

    for (let tolerance of tolerances) {
      if (
        maximumTolerance >= tolerance.range_minimum &&
        maximumTolerance <= tolerance.range_maximum
      ) {
        minimumTolerance = Math.max(minimumTolerance - tolerance.underage, 0)
        maximumTolerance = maximumTolerance + tolerance.overage
        break
      }
    }

    specs[spec.element.name] = {
      minimum: spec.minimum,
      maximum: spec.maximum,
      tolerances,
      minimumTolerance,
      maximumTolerance,
    }
    return specs
  }, {})
}

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

export const GradeShow = props => {
  const specs = useGradeSpec(props.id)
  const elements = lodash.sortBy(Object.keys(specs))

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="class" />
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Measure</StyledTableCell>
                <StyledTableCell>Minimum</StyledTableCell>
                <StyledTableCell>Maximum</StyledTableCell>
                <StyledTableCell>Tolerances</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {elements.map(element => (
                <StyledTableRow key={element}>
                  <StyledTableCell>{element}</StyledTableCell>
                  <StyledTableCell>
                    <NumberField record={specs[element]} source="minimum" options={NUMBER_FORMATTING}/>
                  </StyledTableCell>
                  <StyledTableCell>
                    <NumberField record={specs[element]} source="maximum" options={NUMBER_FORMATTING}/>
                  </StyledTableCell>
                  <StyledTableCell>
                    {specs[element].tolerances.length > 0 && (
                      <ToleranceTable tolerances={specs[element].tolerances} />
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </SimpleShowLayout>
    </Show>
  )
}

const useToleranceStyles = makeStyles({
  table: {
    width: 400,
  },
})

const ToleranceTable = ({ tolerances }) => {
  const classes = useToleranceStyles()

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table className={classes.table} aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Range Min</TableCell>
            <TableCell>Range Max</TableCell>
            <TableCell>Underage</TableCell>
            <TableCell>Overage</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tolerances.map(tolerance => (
            <TableRow key={tolerance.id}>
              <TableCell>
                <NumberField record={tolerance} source="range_minimum" options={NUMBER_FORMATTING}/>
              </TableCell>
              <TableCell>
                <NumberField record={tolerance} source="range_maximum" options={NUMBER_FORMATTING}/>
              </TableCell>
              <TableCell>
                <NumberField record={tolerance} source="underage" options={NUMBER_FORMATTING}/>
              </TableCell>
              <TableCell>
                <NumberField record={tolerance} source="overage" options={NUMBER_FORMATTING}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
