import { fetchUtils, HttpError } from "react-admin"

import hasuraDataProvider from "ra-data-hasura"
import keycloak from "./keycloak"

const API_URL = process.env.REACT_APP_HASURA_BACKEND_URL

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" })
  }

  options.headers.set("Authorization", `Bearer ${keycloak.token}`)
  return new Promise(async (resolve, reject) => {
    try {
      const results = await fetchUtils.fetchJson(url, options)
      return resolve(results)
    } catch (e) {
      if (!e.message) {
        const err = new HttpError(e.body.error, e.status, e.body)
        return reject(err)
      } else {
        return reject(e)
      }
    }
  })
}

export const dataProvider = hasuraDataProvider(API_URL, httpClient)

export const authProvider = {
  login: params => Promise.resolve(),
  logout: params => {
    if (keycloak && keycloak.authenticated) {
      keycloak.logout()
    }
    return Promise.resolve()
  },
  checkAuth: params => {
    const authPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        if (keycloak && keycloak.authenticated) {
          resolve(true)
        } else {
          reject(false)
        }
      }, 1000)
    })
    return authPromise
  },
  checkError: error => Promise.resolve(),
  getPermissions: params => Promise.resolve(),
  getIdentity: () => Promise.resolve(),
}
