import { ApolloClient, HttpLink, split } from "@apollo/client"
import { setContext } from "apollo-link-context"
import { SubscriptionClient } from "subscriptions-transport-ws"
import { WebSocketLink } from "@apollo/client/link/ws"
import { onError } from "@apollo/client/link/error"
import { InMemoryCache } from "@apollo/react-hooks"
import { getMainDefinition } from "@apollo/client/utilities"
import keycloak from "./keycloak"

const cache = new InMemoryCache()
const API_URL = process.env.REACT_APP_HASURA_BACKEND_URL
const WS_URL = process.env.REACT_APP_HASURA_WEBSOCKET_URL

// TODO: Reflect on window.location to determine the backend host.
const httpLink = new HttpLink({
  uri: `${API_URL}/v1/graphql`,
})

export const wsClient = new SubscriptionClient(`${WS_URL}/v1/graphql`, {
  lazy: true,
  reconnect: true,
  timeout: 30000,
  connectionParams: () => {
    const jwt = keycloak.token
    return {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  },
  connectionCallback: (error, result) => {
    if (error) {
      console.error(error.message)
      if (error.message === "Could not verify JWT") {
        // Force reconnect with a new JWT
        wsClient.close()
      }
    }
  },
})

const wsLink = new WebSocketLink(wsClient)

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    )
  },
  wsLink,
  httpLink
)

// Inject the raw and parsed JWT from localStorage.
const jwtLink = setContext((_, ctx) => {
  const jwt = keycloak.token
  if (!jwt) {
    return ctx
  }

  return {
    jwt,
    claims: jwt,
    ...ctx,
  }
})

// Inject the JWT into the Authorization header.
const authLink = setContext((_, { jwt, headers }) => {
  if (!jwt) {
    return {}
  }

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${jwt}`,
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message.includes("Could not verify JWT")) {
        keycloak.logout()
      }
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
      return
    })
  if (networkError) console.log(`[Network error]: ${networkError.message}`)
  return
})

// appLink is all of our other links concatenated in order.
const appLink = jwtLink.concat(authLink).concat(errorLink).concat(splitLink)

const client = new ApolloClient({
  link: appLink,
  cache: cache,
})

export { appLink }
export default client
