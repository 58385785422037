import React, {
  useState,
  useEffect,
  Children,
  isValidElement,
  cloneElement,
} from "react"

import {
  makeStyles,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid,
  CircularProgress,
} from "@material-ui/core"

import classnames from "classnames"

import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons"
import gql from "graphql-tag"
import { useQuery, useLazyQuery, useApolloClient } from "@apollo/react-hooks"
import lodash from "lodash"
import Plot from "react-plotly.js"

import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  FunctionField,
  useShowController,
  NumberField,
  Labeled,
  CardContentInner,
  Filter,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  useListContext,
  downloadCSV,
  useUpdateLoading,
  useGetOne,
} from "react-admin"

import jsonExport from "jsonexport/dist"

import { useGradeSpec } from "./Grades"

// Available options: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
const NUMBER_FORMATTING = {
  maximumFractionDigits: 10
}

const GradeChangeFilter = props => (
  <Filter {...props}>
    <TextInput label="First Heat" source="first_heatnum" alwaysOn />
    <TextInput label="Second Heat" source="second_heatnum" alwaysOn />
    <ReferenceInput
      label="First Grade"
      source="first_grade_id"
      reference="grades"
      sort={{ field: "name", order: "ASC" }}
      filterToQuery={searchText => {
        if (searchText) {
          return {
            name: { _ilike: `${searchText}%` },
          }
        }
      }}
      alwaysOn
    >
      <AutocompleteInput allowEmpty optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Second Grade"
      source="second_grade_id"
      reference="grades"
      sort={{ field: "name", order: "ASC" }}
      filterToQuery={searchText => {
        if (searchText) {
          return {
            name: { _ilike: `${searchText}%` },
          }
        }
      }}
      alwaysOn
    >
      <AutocompleteInput allowEmpty optionText="name" />
    </ReferenceInput>
  </Filter>
)

const PREDICT_GRADE_CHANGES_QUERY = gql`
  query PredictGradeChange($gradeChangeIds: [bigint]!) {
    predict_grade_changes(grade_change_ids: $gradeChangeIds) {
      id
      grade_change_id
      cut_length
    }
  }
`

const headers = [
  "change_date",
  "first_heatnum",
  "second_heatnum",
  "recommended_cut_length_inches",
  "strand_data.first_seq",
  "strand_data.second_seq",
  "strand_data.first_grade.TIN",
  "strand_data.first_grade.LEAD",
  "strand_data.first_grade.ZINC",
  "strand_data.first_grade.BORON",
  "strand_data.first_grade.CARBON",
  "strand_data.first_grade.CARBON_EQUIVALENCE",
  "strand_data.first_grade.COPPER",
  "strand_data.first_grade.NICKEL",
  "strand_data.first_grade.SULFUR",
  "strand_data.first_grade.CALCIUM",
  "strand_data.first_grade.NIOBIUM",
  "strand_data.first_grade.SILICON",
  "strand_data.first_grade.ALUMINUM",
  "strand_data.first_grade.CHROMIUM",
  "strand_data.first_grade.NITROGEN",
  "strand_data.first_grade.TITANIUM",
  "strand_data.first_grade.VANADIUM",
  "strand_data.first_grade.MANGANESE",
  "strand_data.first_grade.ZIRCONIUM",
  "strand_data.first_grade.MOLYBDENUM",
  "strand_data.first_grade.PHOSPHORUS",
  "strand_data.second_grade.TIN",
  "strand_data.second_grade.LEAD",
  "strand_data.second_grade.ZINC",
  "strand_data.second_grade.BORON",
  "strand_data.second_grade.CARBON",
  "strand_data.second_grade.CARBON_EQUIVALENCE",
  "strand_data.second_grade.COPPER",
  "strand_data.second_grade.NICKEL",
  "strand_data.second_grade.SULFUR",
  "strand_data.second_grade.CALCIUM",
  "strand_data.second_grade.NIOBIUM",
  "strand_data.second_grade.SILICON",
  "strand_data.second_grade.ALUMINUM",
  "strand_data.second_grade.CHROMIUM",
  "strand_data.second_grade.NITROGEN",
  "strand_data.second_grade.TITANIUM",
  "strand_data.second_grade.VANADIUM",
  "strand_data.second_grade.MANGANESE",
  "strand_data.second_grade.ZIRCONIUM",
  "strand_data.second_grade.MOLYBDENUM",
  "strand_data.second_grade.PHOSPHORUS",
  "strand_data.first_timestamp",
  "strand_data.second_timestamp",
  "strand_data.scrap_length_inches",
]

const exporter = (
  apolloClient,
  startLoading,
  stopLoading
) => async gradeChanges => {
  startLoading()
  const gradeChangeIds = gradeChanges.map(gc => gc.id)
  let gradeChangesForExport = gradeChanges

  try {
    const { data } = await apolloClient.query({
      query: PREDICT_GRADE_CHANGES_QUERY,
      variables: { gradeChangeIds },
    })
    const predictionByGradeChange = lodash.keyBy(
      data.predict_grade_changes,
      "grade_change_id"
    )
    gradeChangesForExport = gradeChanges.map(gradeChange => {
      const { ...gradeChangeForExport } = gradeChange // omit backlinks and author
      gradeChangeForExport.recommended_cut_length_inches =
        predictionByGradeChange[gradeChange.id]?.cut_length
      return gradeChangeForExport
    })
  } catch (err) {
    console.error(err)
  }

  jsonExport(
    gradeChangesForExport,
    {
      headers,
    },
    (err, csv) => {
      console.error(err)
      stopLoading()
      downloadCSV(csv, "grade_changes") // download as 'posts.csv` file
    }
  )
}

const useGradeChangePredictions = gradeChangeIds => {
  const [predictions, setPredictions] = useState([])
  const [getPredictions, { data, loading, error }] = useLazyQuery(
    PREDICT_GRADE_CHANGES_QUERY
  )

  useEffect(() => {
    if (gradeChangeIds.length > 0) {
      getPredictions({ variables: { gradeChangeIds } })
    }
  }, [getPredictions, gradeChangeIds])

  useEffect(() => {
    if (data && data.predict_grade_changes) {
      setPredictions(data.predict_grade_changes)
    }
  }, [data])

  return { predictions, loading, error }
}

export const GradeChangeList = props => {
  const apolloClient = useApolloClient()
  const { startLoading, stopLoading } = useUpdateLoading()
  return (
    <List
      {...props}
      exporter={exporter(apolloClient, startLoading, stopLoading)}
      sort={{ field: "change_date", order: "DESC" }}
      filters={<GradeChangeFilter />}
    >
      <GradeChangeDataGrid />
    </List>
  )
}

const GradeChangeDataGrid = props => {
  const { ids: gradeChangeIds } = useListContext()
  const { predictions } = useGradeChangePredictions(gradeChangeIds)
  const predictionByGradeChange = lodash.keyBy(predictions, "grade_change_id")

  return (
    <Datagrid {...props} rowClick="show">
      <DateField source="change_date" label="Date" />
      <TextField source="first_heatnum" label="First Heat" />
      <ReferenceField
        label="First Grade"
        source="first_grade_id"
        reference="grades"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="second_heatnum" label="Second Heat" />
      <ReferenceField
        label="Second Grade"
        source="second_grade_id"
        reference="grades"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <FunctionField
        label="Recommended Cut Length (in.)"
        render={record => {
          const prediction = predictionByGradeChange[record.id]
          return (
            (prediction && prediction.cut_length) || (
              <CircularProgress color="secondary" size={20} />
            )
          )
        }}
      />
    </Datagrid>
  )
}

const sanitizeRestProps = ({
  children,
  className,
  record,
  resource,
  basePath,
  version,
  initialValues,
  translate,
  ...rest
}) => rest

export const GradeChangeShow = props => {
  const {
    resource,
    basePath,
    loaded, // boolean that is false until the record is available
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props)

  const commonProps = { record, resource, basePath, addLabel: true }

  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item sm={2}>
          <CardContentInner {...sanitizeRestProps(props)}>
            <CustomFieldLayout {...commonProps}>
              <DateField source="change_date" label="Date" />
            </CustomFieldLayout>
            <CustomFieldLayout {...commonProps}>
              <TextField source="first_heatnum" label="First Heat" />
            </CustomFieldLayout>
            <CustomFieldLayout {...commonProps}>
              <ReferenceField
                label="First Grade"
                source="first_grade_id"
                reference="grades"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
            </CustomFieldLayout>
            <CustomFieldLayout {...commonProps}>
              <ReferenceField
                label="First Grade Class"
                source="first_grade_id"
                reference="grades"
                link="show"
              >
                <TextField source="class" />
              </ReferenceField>
            </CustomFieldLayout>
            <CustomFieldLayout {...commonProps}>
              <TextField source="second_heatnum" label="Second Heat" />
            </CustomFieldLayout>
            <CustomFieldLayout {...commonProps}>
              <ReferenceField
                label="Second Grade"
                source="second_grade_id"
                reference="grades"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
            </CustomFieldLayout>
            <CustomFieldLayout {...commonProps}>
              <ReferenceField
                label="Second Grade Class"
                source="second_grade_id"
                reference="grades"
                link="show"
              >
                <TextField source="class" />
              </ReferenceField>
            </CustomFieldLayout>
          </CardContentInner>
        </Grid>
        <Grid item sm={10}>
          {record && <GradeChangeGraphs gradeChange={record} />}
        </Grid>
        <Grid item sm={12}>
          {loaded && (
            <StrandTable record={record} strands={record.strand_data} />
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  inTolerance: { color: "green" },
  outOfTolerance: { color: "red" },
})

const inTolerance = (specs, sample) => {
  // let inSpec = true
  //for symbol in elements_to_check:
  const elementsSpec = Object.values(elementsInTolerance(specs, sample))
  return elementsSpec.length === elementsSpec.filter(inSpec => inSpec)
}

const elementsInTolerance = (specs, sample) => {
  return Object.keys(specs).reduce((elementsSpec, element) => {
    const fromSample = sample[element]
    const fromGrade = specs[element]

    // Treat min/max both zero to mean the level of symbol isn't relevant
    if (fromGrade.minimum === 0 && fromGrade.maximum === 0) {
      elementsSpec[element] = true
    } else {
      let minimum = fromGrade.minimum
      let maximum = fromGrade.maximum
      for (let tolerance of fromGrade.tolerances) {
        if (
          maximum >= tolerance.range_minimum &&
          maximum <= tolerance.range_maximum
        ) {
          minimum = Math.max(minimum - tolerance.underage, 0)
          maximum = maximum + tolerance.overage
          break
        }
      }

      if (minimum > fromSample || maximum < fromSample) {
        elementsSpec[element] = false
      } else {
        elementsSpec[element] = true
      }
    }

    return elementsSpec
  }, {})
}

const StrandRow = ({ firstSpecs, secondSpecs, strand }) => {
  const classes = useRowStyles()
  const [open, setOpen] = React.useState(true)
  const first = strand.first_grade
  const second = strand.second_grade
  const elements = lodash.sortBy(Object.keys(first))
  const firstElementsTolerance = elementsInTolerance(firstSpecs, second)
  const secondElementsTolerance = elementsInTolerance(secondSpecs, second)

  // Strand number, Strand length, start time, end time, second grade in spec, second grade in tolerance
  // Expanded table showing the start and end values of each element
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align="right">{strand.scrap_length_inches}</TableCell>
        <TableCell align="right">
          <DateField record={strand} source="first_timestamp" showTime />
        </TableCell>
        <TableCell align="right">
          <DateField record={strand} source="second_timestamp" showTime />
        </TableCell>
        <TableCell align="right">
          {inTolerance(secondSpecs, strand.second_grade).toString()}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Grade Measures
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Element</TableCell>
                    <TableCell component="th">First Grade</TableCell>
                    <TableCell component="th">Second Grade</TableCell>
                    <TableCell component="th">Second Tolerance Lower</TableCell>
                    <TableCell component="th">Second Tolerance Upper</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {elements.map(element => (
                    <TableRow key={element}>
                      <TableCell>{element}</TableCell>
                      <TableCell>
                        <NumberField
                          className={
                            firstElementsTolerance[element]
                              ? classes.inTolerance
                              : classes.outOfTolerance
                          }
                          record={first}
                          source={element}
                          options={NUMBER_FORMATTING}
                        />
                      </TableCell>
                      <TableCell
                        className={
                          secondElementsTolerance[element]
                            ? classes.inTolerance
                            : classes.outOfTolerance
                        }
                      >
                        <NumberField record={second} source={element} options={NUMBER_FORMATTING} />
                      </TableCell>
                      <TableCell>
                        <NumberField
                          record={secondSpecs[element]}
                          source="minimumTolerance"
                          options={NUMBER_FORMATTING}
                        />
                      </TableCell>
                      <TableCell>
                        <NumberField
                          record={secondSpecs[element]}
                          source="maximumTolerance"
                          options={NUMBER_FORMATTING}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

const StrandTable = ({ strands, record }) => {
  const firstSpecs = useGradeSpec(record.first_grade_id)
  const secondSpecs = useGradeSpec(record.second_grade_id)

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="right">Scrap Length (In.)</TableCell>
            <TableCell align="right">First Grade Timestamp</TableCell>
            <TableCell align="right">Second Grade Timestamp</TableCell>
            <TableCell align="right">Second Grade In Tolerance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {strands.map((strand, idx) => (
            <StrandRow
              key={idx}
              strand={strand}
              secondSpecs={secondSpecs}
              firstSpecs={firstSpecs}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const CustomFieldLayout = ({ children, record, resource, basePath }) => {
  return (
    <div>
      {Children.map(children, field =>
        field && isValidElement(field) ? (
          <div
            key={field.props.source}
            className={classnames(
              `ra-field ra-field-${field.props.source}`,
              field.props.className
            )}
          >
            {field.props.addLabel ? (
              <Labeled
                record={record}
                resource={resource}
                basePath={basePath}
                label={field.props.label}
                source={field.props.source}
                disabled={false}
              >
                {field}
              </Labeled>
            ) : typeof field.type === "string" ? (
              field
            ) : (
              cloneElement(field, {
                record,
                resource,
                basePath,
              })
            )}
          </div>
        ) : null
      )}
    </div>
  )
}

/*
const LATEST_PREDICTION_TYPE_QUERY = gql`
  query LatestPredictionType {
    prediction_types(
      limit: 1
      order_by: { created_at: desc }
      where: {
        _and: [
          { training_complete: { _eq: true } }
          { name: { _eq: "Scaled Numerical Approximation" } }
        ]
      }
    ) {
      id
      name
    }
  }
`
*/

const PREDICT_GRADE_CHANGE_QUERY = gql`
  query PredictGradeChange($gradeChangeId: bigint!) {
    predict_grade_change(grade_change_id: $gradeChangeId) {
      id
      grade_change_id
      cut_length
      elements {
        element_name
        cut_length
        predictions {
          value
          in_tol
          length
        }
      }
    }
  }
`

const useGradeChangePrediction = gradeChangeId => {
  const [predictionData, setPredictionData] = useState(null)
  const [cut, setCut] = useState(null)
  const { data, loading, error } = useQuery(PREDICT_GRADE_CHANGE_QUERY, {
    variables: { gradeChangeId },
  })

  useEffect(() => {
    if (data && data.predict_grade_change) {
      setCut(data.predict_grade_change.cut_length)
      setPredictionData(data.predict_grade_change.elements)
    }
  }, [data])

  return { predictionData, cut, loading, error }
}

export const GradeChangeGraphs = ({ gradeChange }) => {
  const { predictionData, cut, loading } = useGradeChangePrediction(
    gradeChange.id
  )

  const { data: secondGrade = {} } = useGetOne('grades', gradeChange.second_grade_id);

  //let cut
  let xMax

  if (predictionData) {
    xMax = Math.ceil(
      Math.max(
        ...predictionData.map(elementPrediction => {
          const { predictions } = elementPrediction
          return predictions[predictions.length - 1]["length"]
        })
      )
    )
  }

  return (
    <Grid container spacing={1}>
      <Grid sm={12}>
        <div className="prediction-header">
          In Tolerance Prediction: <b>{cut} inches</b>
          {secondGrade.class === 'SBQ Rod' &&
          <span> - SBQ Rod Max: <b>{xMax} inches</b> </span>
          }
        </div>
      </Grid>
      {loading || !predictionData ? (
        <Grid item sm={12}>
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        lodash
          .chain(predictionData)
          .sortBy(["element_name"])
          .map(elementPrediction => {
            const { element_name: element, predictions } = elementPrediction
            const X = predictions.map(p => p.length)
            const Y = predictions.map(p => p.value)
            let firstInTolIndex = lodash.findIndex(predictions, p => p.in_tol)
            // const recommendedCutLength = Math.ceil(X[firstInTolIndex])
            const recommendedCutLength = elementPrediction.cut_length
            const notInTolerance = firstInTolIndex === -1

            const elementName = element.split("_").join(" ")

            firstInTolIndex = notInTolerance
              ? predictions.length
              : firstInTolIndex

            const XOut = X.slice(0, firstInTolIndex + 1)
            const XIn = X.slice(
              firstInTolIndex === 0 ? 0 : firstInTolIndex,
              X.length
            )
            const YOut = Y.slice(0, firstInTolIndex + 1)
            const YIn = Y.slice(
              firstInTolIndex === 0 ? 0 : firstInTolIndex,
              Y.length
            )

            const maxY = notInTolerance ? Y[Y.length - 1] : Y[firstInTolIndex]
            const verticalX = (notInTolerance ? X[X.length - 1] : X[firstInTolIndex]) - 2

            //? `${element} doesn't go in tolerance`
            const title = notInTolerance
              ? `${elementName} in Grade 2 was out of tolerance before intermix. As a result, predictions will never be in tolerance`
              : `${elementName} Predicted to be in tolerance at ${recommendedCutLength} inches`

            return (
              <Grid key={element} item sm={12}>
                <Plot
                  data={[
                    {
                      x: XOut,
                      y: YOut,
                      type: "scatter",
                      mode: "lines",
                      fill: "tozeroy",
                      name: elementName + " Out of Tolerance",
                      marker: { color: "red" },
                      showlegend: false,
                    },
                    {
                      x: XIn,
                      y: YIn,
                      type: "scatter",
                      mode: "lines",
                      fill: "tozeroy",
                      name: elementName + " In Tolerance",
                      marker: { color: "green" },
                      showlegend: false,
                    },
                  ]}
                  layout={{
                    margin: {
                      l: 50,
                      t: 30,
                      b: 30,
                    },
                    width: 800,
                    height: 120,
                    title: title,
                    xaxis: { title: "Scrap Length (In.)", range: [0, xMax] },
                    yaxis: {
                      title: {
                        text: elementName,
                        font: {
                          size: 10,
                        }
                      }
                    },
                    shapes: [
                      {
                        type: "line",
                        x0: verticalX,
                        y0: 0,
                        x1: verticalX,
                        y1: maxY,
                        line: {
                          color: 'green',
                          width: 5,
                          // dash: 'dot'
                        }
                      }
                    ]
                  }}
                  config={{ displayModeBar: false }}
                />
              </Grid>
            )
          })
          .value()
      )}
    </Grid>
  )
}
